import { render, staticRenderFns } from "./group_task.vue?vue&type=template&id=7619bd0b&scoped=true&"
import script from "./group_task.vue?vue&type=script&lang=js&"
export * from "./group_task.vue?vue&type=script&lang=js&"
import style0 from "./group_task.vue?vue&type=style&index=0&id=7619bd0b&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7619bd0b",
  null
  
)

export default component.exports