<template>
  <div class="main_content">
    <div class="table_contet">
      <div class="left_table">
          <group-list :isSatis="true" @showDetails="showDetails"></group-list>
      </div>
      <div class="right_table">
        <group_task :groupInfo="groupInfo"></group_task>
      </div>
    </div>
  </div>
</template>

<script>
import groupList from  '../group-rank/index'
import Group_task from "./components/group_task";
    export default {
      name: "index",
      data() {
        return {
          groupInfo: {}
        }
      },
      components: {
        Group_task,
        groupList
      },
      methods: {
        showDetails(data) {
          this.groupInfo = data
        },
      }
    }
</script>

<style scoped lang="less">
.table_contet{
  height: 100%;
  display: flex;
  .left_table{
    width: 30%;
    height: 100%;
    margin-right: 10px;
  }
  .right_table{
    width:70%;
    height: 100%;
  }
}
</style>
