<template>
  <div class="main_content" style="margin: 0;width: 100%;height: 100%">
    <div class="title_box">
      <span></span>
      <h5>打卡记录</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" style="height: 52px;"></div>
      <div class="operate_btn"> <el-button type="primary" icon="el-icon-download" @click="exportTask">导出</el-button>
      </div>
    </div>
    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column prop="id" label="id" width="100" />
        <el-table-column prop="taskTypeName" label="任务类型" />
        <el-table-column prop="submitTypeName" label="打卡方式" />
        <el-table-column prop="score" label="获得积分" />
        <el-table-column prop="rewardScore" label="奖励积分" />
        <el-table-column prop="sumScore" label="积分合计" />
        <el-table-column prop="created" label="获得积分时间" />
        <el-table-column prop="sourceName" label="积分来源" />
        <el-table-column prop="userName" label="姓名" />
        <el-table-column width="60" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
              <el-button type="primary" circle icon="el-icon-document" @click="taskDetails(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
    <!--打卡详情-->
    <el-dialog :visible.sync="taskDialog" width="40%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ userName }}打卡详情
      </div>
      <task-info :taskInfoBox="taskInfoBox" :key="timer"></task-info>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import { Loading } from "element-ui";
import TaskInfo from "../../../views/person-statics/components/task-info";
export default {
  props: {
    groupInfo: {
      type: Object,
      required: true
    }
  },
  name: "group_task",
  data() {
    return {
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
        id: null
      },
      tableData: [],
      taskDialog: false,
      taskInfoBox: {},
      timer: '',
      userName: ''
    }
  },
  components: {
    TaskInfo
  },
  methods: {

    //根据小组id查询打卡列表
    async getGroupTask() {
      try {
        let res = await api.groupTaskGet(this.pageBox)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGroupTask(this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGroupTask(this.pageBox)
    },
    //导出打卡小组打卡列表
    async exportTask() {
      if (this.groupInfo.groupName == undefined) {
        this.$message.warning('请选择一个小组打卡数据进行导出')
        return false
      }
      let name = this.groupInfo.groupName + '打卡记录'
      const loading = Loading.service({
        lock: true,
        text: name + '导出中',
        spinner: 'el-icon-loading',
        background: "rgba(0,0,0,0.7)",
      });
      try {
        let res = await api.exportGroupTask(this.groupInfo.groupId)
        loading.close();
        let blob = new Blob([res], { type: "xls" });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = `${name}.xls`; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
        this.$message.success(`导出成功`);
      } catch (e) {
        this.$message.warning(e.msg)
      }
    },
    async taskDetails(row) {
      this.userName = row.userName
      try {
        let res = await api.getTaskDetails(row.id)
        if (res.code == 200) {
          this.timer = new Date().getTime()
          this.taskDialog = true
          this.taskInfoBox = res.data
        }
      } catch (e) {
        this.$message.warning(e.msg)
      }
    }
  },
  watch: {
    groupInfo: {
      handler(newVal, oldVal) {
        this.pageBox.id = newVal.groupId
        this.getGroupTask()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

.table_box {
  height: calc(100% - 153px);
  margin: 10px;
}
</style>
